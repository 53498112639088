'format es6';
'use strict';

const dom = {};

function setupReferences() {
	dom.days = document.querySelector('[data-countdown-days]');
	dom.hours = document.querySelector('[data-countdown-hours]');
	dom.min = document.querySelector('[data-countdown-min]');
	dom.sec = document.querySelector('[data-countdown-sec]');
	dom.timer = document.querySelector('[data-countdown-timer]');
}

function countdown(endDate) {
	endDate = new Date(endDate).getTime();
	let days;
	let hours;
	let	minutes;
	let	seconds;
	
	function calculate() {
		let startDate = new Date();
		startDate = startDate.getTime();

		let timeRemaining = parseInt((endDate - startDate) / 1000, 10);

		if (timeRemaining >= 0) {
			days = parseInt(timeRemaining / 86400, 10);
			timeRemaining %= 86400;
			
			hours = parseInt(timeRemaining / 3600, 10);
			timeRemaining %= 3600;
			
			minutes = parseInt(timeRemaining / 60, 10);
			timeRemaining %= 60;
			
			seconds = parseInt(timeRemaining, 10);
			
			requestAnimationFrame(() => {
				dom.days.innerHTML = parseInt(days, 10);
				dom.hours.innerHTML = (`0${hours}`).slice(-2);
				dom.min.innerHTML = (`0${minutes}`).slice(-2);
				dom.sec.innerHTML = (`0${seconds}`).slice(-2);
			});
		} else {
			requestAnimationFrame(() => {
				dom.timer.innerHTML = '00 : 00 : 00 : 00';
			});
		}
	}

	setInterval(calculate, 1000);
}

export default {
	init() {
		setupReferences();
		
		if (dom.days) {
			countdown('09/30/2019 10:00:00 AM'); 
		}
	},
};
