'format es6';
'use strict';

import './utils/polyfills';
import $ from 'jquery';

import imagesloaded from 'imagesloaded';
import objectFitImages from 'object-fit-images';
import objectFitVideos from 'object-fit-videos';
import { Animator } from '@lagrange/animator';

import { docReady } from './utils/docReady';
import { isEdge } from './utils/browserDetect';

import { Animations } from './Animations';

import Forms from './Forms';
import Menu from './Menu';
import Countdown from './Countdown';
import Arrows from './Arrows';
import Newsletter from './Newsletter';
import Career from './Career';
import Anchors from './Anchors';
import Cart from './Cart';
import CartSidebar from './CartSidebar';
import CartIcon from './CartIcon';
import GiftCard from './GiftCard';
import Checkout from './Checkout';
import Fog from './fog';
import VideoPlayer from './VideoPlayer';
import Collapser from './Collapser';
import Filters from './Filters';
import VideoBackground from './VideoBackground';

docReady.then(() => {
	if (isEdge([12, 13, 14])) {
		$('img').removeAttr('srcset').removeAttr('sizes');
	}

	// 2. This code loads the IFrame Player API code asynchronously.
	const tag = document.createElement('script');
	tag.src = 'https://www.youtube.com/iframe_api';
	
	const firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	const animator = new Animator();
	animator.setAnimations(Animations.get(window.innerWidth));

	const onResize = () => {
		animator.setAnimations(Animations.get(window.innerWidth));
	};

	const onImagesLoaded = () => {
		animator.setAnimations(Animations.get(window.innerWidth));
	};

	imagesloaded(document.querySelector('body'), onImagesLoaded);
	window.addEventListener('resize', onResize);

	[
		Menu,
		Forms,
		Countdown,
		Arrows,
		Newsletter,
		Career,
		Anchors,
		Collapser,
		GiftCard,
		Cart,
		CartSidebar,
		CartIcon,
		Checkout,
		Fog,
		VideoPlayer,
		Filters,
		VideoBackground,
	].forEach(c => c.init());

	objectFitImages();
	objectFitVideos();

	document.querySelectorAll('video[autoplay]').forEach(video => video.play());

});
