'format es6';
'use strict';

import {
	ELEM_BOTTOM,
	ELEM_TOP,
	ELEM_CENTER,
	SCREEN_BOTTOM,
	SCREEN_TOP,
	SCREEN_CENTER,
} from '@lagrange/animator';

export const Animations = {
	get(isMobile) {
		const animations = {};
		
		animations.reveal_video = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					scale: 0.7,
				},
				{
					when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
					scale: 1,
				},
			],
			children: [
				{
					selector: 'iframe, img, video',
					ease: 'easeInOutCubic',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							scale: 1 / 0.7,
						},
						{
							when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
							scale: 1,
						},
					],
				},
			],
		};
		
		animations.logo_reveal = {
			children: [
				{
					selector: '.logo-wrapper',
					ease: 'easeInOutCubic',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							y: '-40vh',
							opacity: 0,
						},
						{
							when: `${ELEM_TOP}_${SCREEN_CENTER}`,
							opacity: 1,
						},
						{
							when: `${ELEM_TOP}_${SCREEN_TOP}`,
							y: '0vh',
						},
					],
				},
			],
		};

		animations.right_fog = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_CENTER}`,
					y: '10vh',
				},
				{
					when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
					y: '-10vh',
				},
			],
		};

		animations.left_fog = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_CENTER}`,
					y: '5vh',
				},
				{
					when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
					y: '-5vh',
				},
			],
		};

		animations.large_fog = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_CENTER}`,
					y: '10vh',
				},
				{
					when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
					y: '-10vh',
				},
			],
		};

		animations.small_parallax = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					y: '2.5vh',
				},
				{
					when: `${ELEM_TOP}_${SCREEN_CENTER}`,
				},
				{
					when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
					y: '-2.5vh',
				},
			],
		};

		animations.small_parallax_relative = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					y: '10%',
				},
				{
					when: `${ELEM_TOP}_${SCREEN_CENTER}`,
				},
				{
					when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
					y: '-10%',
				},
			],
		};

		animations.small_inverse_parallax = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					y: isMobile ? 0 : '-2.5vh',
				},
				{
					when: `${ELEM_TOP}_${SCREEN_CENTER}`,
				},
				{
					when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
					y: isMobile ? 0 : '2.5vh',
				},
			],
		};

		animations.fadein_parallax = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					scaleX: 0.9,
					scaleY: 0.9,
					opacity: 0,
				},
				{
					when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
					scaleX: 1,
					scaleY: 1,
					opacity: 1,
				},
			],
		};

		return animations;
	},
};
