'format es6';
'use strict';

import $ from 'jquery';

function removeError(e) {
	const el = $(e.currentTarget);
	
	if (el.hasClass('error')) {
		el.removeClass('error');
	}
}

function handleInputsChange(e) {
	if (!e) return;
	const el = $(e.currentTarget);

	if (el.val() === '') {
		el.addClass('empty');
		el.siblings().removeClass('visible');
	} else {
		el.removeClass('empty');
		el.siblings().addClass('visible');
	}

	removeError(e);
}

function setupRadios() {
	const radiosGroups = document.querySelectorAll('[data-radios]');
	radiosGroups.forEach((radiosGroup) => {
		const inputs = radiosGroup.querySelectorAll('input[type=radio]');
		const buttons = radiosGroup.querySelectorAll('.radio');

		buttons.forEach((button, i) => {
			button.addEventListener('click', () => {
				inputs[i].click();
			});
		});
	});
}

function setupCheckboxes() {
	const checkboxes = document.querySelectorAll('[data-checkbox]');
	checkboxes.forEach((checkbox) => {
		const check = checkbox.querySelector('input[type=checkbox]');
		const button = checkbox.querySelector('.box');

		button.addEventListener('click', () => {
			check.click();
		});
	});
}


export default {
	init() {
		const file_inputs = $('input[type=file]');
		const input_fields = $('input[type=text], input[type=tel], input[type=email], input[type=number], input[type=password], textarea');
		input_fields.on('keypress blur change input', handleInputsChange).trigger('blur');

		const inputFields = document.querySelectorAll('input[type=text], input[type=tel], input[type=email], input[type=password], textarea');

		if (inputFields) {
			inputFields.forEach((input) => {
				['keypress', 'blur', 'change', 'input'].forEach(event => {
					input.addEventListener(event, handleInputsChange);
				});
				const inputValue = input.getAttribute('value');
				if (inputValue !== null && inputValue !== '') {
					handleInputsChange(input, true);
				}
			});

			$('select').on('keypress blur change input', removeError).trigger('blur');

			setupRadios();
			setupCheckboxes();
		}	
	},
};
