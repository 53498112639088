'format es6';
'use strict';

function onAnchorClick(e) {
	e.preventDefault();
	
	const targetEl = e.currentTarget;
	const targetHref = /#(.+)/.exec(targetEl.getAttribute('href'))[1]; //targetEl.getAttribute('href').substring(1);
	const target = document.getElementById(targetHref);

	if (target) {
		target.scrollIntoView({ behavior: 'smooth' }); // scrollToElem(target, { container: $('main') });
	}
}

export default {
	init() {
		const anchorLinks = document.querySelectorAll('a[href^="#"], a[href^="/"][href*="#"]');
		
		if (anchorLinks) {
			anchorLinks.forEach((curr) => {
				curr.addEventListener('click', onAnchorClick);
			});
		}
	},
};
