import Cart, { CART_UPDATED_EVENT, CART_ITEM_ADDED_EVENT, generateItem } from './Cart';
import ns from './Namespace';

const dom = {};

function init() {
	build();
	addListeners();

	onCartUpdated();
}

function build() {
	dom.body = document.querySelector('body');
	dom.ctn = document.querySelector('[data-sidebar-cart]');
	dom.close = dom.ctn.querySelector('[data-close]');
	dom.list = dom.ctn.querySelector('[data-item-list]');
	dom.count = dom.ctn.querySelector('[data-cart-item-count]');
	dom.subtotal = dom.ctn.querySelector('[data-subtotal]');
	dom.subtotal_value = dom.ctn.querySelector('[data-subtotal-value]');
	dom.empty_cart = dom.ctn.querySelector('[data-empty-cart]');
	dom.togo_checkout = dom.ctn.querySelector('[data-goto-checkout]');
}

function addListeners() {
	dom.close.addEventListener('click', onClickClose);
	window.addEventListener(CART_UPDATED_EVENT, onCartUpdated);
	window.addEventListener(CART_ITEM_ADDED_EVENT, openCart);
}

function onCartUpdated() {

	dom.list.innerHTML = '';
	const list = Cart.get();
	if (list.length === 0) {
		dom.togo_checkout.classList.add('disabled');
		dom.empty_cart.innerHTML = `<div class="checkout-cart-empty">${ns.tx.cartEmpty}</div>`;
	} else {
		dom.togo_checkout.classList.remove('disabled');
		dom.empty_cart.innerHTML = '';
	}

	list.forEach(item => {
		const elem = generateItem(item);
		elem.querySelector('[data-remove-item]').addEventListener('click', onClickRemoveItem);
		dom.list.appendChild(elem);
	});
	dom.count.innerHTML = list.length;

	dom.subtotal.style.display = list.length === 0 ? 'none' : '';
	dom.subtotal_value.innerHTML = Cart.getSubtotal();
}

function openCart() {
	dom.ctn.classList.remove('closed');
}

function toggleCart() {
	dom.ctn.classList.toggle('closed');
}

function onClickClose() {
	toggleCart();
}

function onClickRemoveItem(e) {
	e.currentTarget.removeEventListener('click', onClickRemoveItem);
	Cart.removeItem(e.currentTarget.getAttribute('data-remove-item'));
}

export default {
	init,
	toggleCart,
	openCart,
};
