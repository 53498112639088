import 'intersection-observer';

export default {
	init() {
		const videoObserver = new IntersectionObserver((entries) => {
		
			if (entries) {
				entries.forEach(entry => {
					const { target } = entry;
					if (!entry.isIntersecting && !target.paused) {
						console.log(target);
					} else if (entry.isIntersecting && target.paused) {
						console.log(target.play());
					}
				});
			}
		});

		document.querySelectorAll('video').forEach(vid => {
			if (vid.autoplay) {
				// vid.pause();
				videoObserver.observe(vid);
			}
		});
	},
};

