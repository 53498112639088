'format es6';
'use strict';

import ns from 'ns';
import $ from 'jquery';
import { TweenMax } from 'gsap';

function handleSuccessNewsletter(e) {
	e.preventDefault();

	const form = $(e.currentTarget);
	const isActive = $('.active');

	if (isActive) {
		const invalid = $('[data-invalid]');
		const field = $('.inputs', form);
		const message = $('.message', form);

		if (!form[0].checkValidity()) {
			invalid.show();
			return;
		}
		invalid.hide();

		console.log(form.serialize());

		$.ajax({
			url: form.attr('action'),
			method: 'post',
			data: form.serialize(),
			dataType: 'json',
			success: (data) => {

				console.log(data);

				if (data.result_code) {
					console.log('success');
					field.slideUp(() => {
						field.removeClass('error');
						message.removeClass('error').addClass('success');
						message.empty().text(ns.newsletterSuccess); 
						message.slideDown();
					});
					const dataLayer = window.dataLayer || [];
					dataLayer.push({
						event: 'forms',
						eventAction: 'submitted',
						eventLabel: 'newsletter',
					});
				} else if (data === 'exists') {
					console.log('user exist');
					field.addClass('error');
					message.empty().text(ns.newsletterUserExists); 
					message.slideDown();
				} else {
					console.log('error');
					message.empty().text(ns.newsletterError); 
					message.addClass('error');
					message.slideDown();
				}
			},
		});
	}
}

function handleKeys(e) {
	const form = $('[data-form-newsletter]');

	const myInput = document.querySelector('[data-validation-type]');
	const submitBtn = document.querySelector('.newsletter_submit');
	const iconArrow = submitBtn.querySelector('.icon-arrow');

	const isValid = myInput.checkValidity();

	if (isValid) {
		iconArrow.style.opacity = 1;
		submitBtn.classList.add('active');

		form.on('submit', (event) => {
			handleSuccessNewsletter(event);
		});
	} else {
		iconArrow.style.opacity = 0.3;
		submitBtn.classList.remove('active');
	}
}

export default {
	init() {
		const form = $('[data-form-newsletter]');
		if (form) {
			window.addEventListener('keyup', handleKeys);
		}
	},
};
