'format es6';
'use strict';

import $ from 'jquery';
import ns from 'ns';
import { getParameterByName } from './utils/getParameterByName';
import BookingManager from './BookingManager';

function setTags(e) {
	const clicked = e.currentTarget;
	const clickedLabel = clicked.textContent;

	window.dataLayer.push({
		event: 'cta',
		eventAction: 'button click',
		eventLabel: `${clickedLabel}`,
	});
}

function checkout(cart, coupon = '') {
	const products = cart.map(formatProduct);
	// window.gtag('event', 'begin_checkout', {
	// 	items: products,
	// 	coupon,
	// });
	window.dataLayer.push({
		event: 'checkout',
		ecommerce: {
			checkout: {
				actionField: { step: 1, coupon },
				products,
			},
		},
	});
}

function checkoutProgress(cart, coupon = '') {
	const products = cart.map(formatProduct);
	// window.gtag('event', 'checkout_progress', {
	// 	items: products,
	// 	coupon,
	// });
	window.dataLayer.push({
		event: 'checkout',
		ecommerce: {
			checkout: {
				actionField: { step: 2, coupon },
				products,
			},
		},
	});
}

function formatProduct(product) {
	let name = 'Carte cadeau monétaire';
	if (product.treatment || product.pckg) name = product.description;

	let id = 'gift-card';
	if (product.treatment) id = product.treatment.booker_id;
	if (product.pckg) id = product.pckg.booker_id;

	return {
		name,
		id,
		price: product.value,
		brand: (product.location && product.location.name) || 'none',
		category: product.type,
		variant: 'delivery[' + product.delivery_type + '], template[' + product.template_id + ']',
		quantity: product.quantity || 1,
	};
}

function formatTreatment(treatment) {
	const location = ns.locations.find(loc => loc.id === treatment.location_id) || { name: '' };
	return {
		name: treatment.name,
		id: treatment.booker_id,
		price: treatment.price,
		brand: location.name,
		category: 'reservation',
		variant: 'is_duo[' + treatment.is_duo + '], is_weekend[' + treatment.is_weekend + ']',
		quantity: 1,
	};
}

function addRemoveCart(product, isAdd = true) {
	// window.gtag('event', isAdd ? 'add_to_cart' : 'remove_from_cart', {
	// 	items: [formatProduct(product)],
	// });
	window.dataLayer.push({
		event: isAdd ? 'add_to_cart' : 'remove_to_cart',
		ecommerce: {
			[isAdd ? 'add' : 'remove']: {
				products: [formatProduct(product)],
			},
		},
	});
}

function payment(cart) {
	const transaction_info = JSON.parse(localStorage.getItem('transaction_info') || '{}');
	window.dataLayer.push({
		event: 'purchase',
		ecommerce: {
			purchase: {
				actionField: {
					id: getParameterByName('id'),
					revenue: transaction_info.total,
					currency: 'CAD',
				},
				products: cart.map(formatProduct),
			},
		},
	});
}

function paymentReservation(transactionNumber, cart) {
	const treatment = BookingManager.computeService();

	window.dataLayer.push({
		event: 'purchase',
		ecommerce: {
			purchase: {
				actionField: {
					id: transactionNumber,
					revenue: BookingManager.getPrice(),
					currency: 'CAD',
				},
				products: [formatTreatment(treatment)],
			},
		},
	});
}

function addReservationToCart(treatment) {
	// window.gtag('event', 'add_to_cart', {
	// 	items: [formatTreatment(treatment)],
	// });
	window.dataLayer.push({
		event: 'add_to_cart',
		ecommerce: {
			add: {
				products: [formatTreatment(treatment)],
			},
		},
	});
}

function checkoutReservation() {
	// window.gtag('event', 'begin_checkout', {
	// 	items: [],
	// 	coupon: '',
	// });
	window.dataLayer.push({
		event: 'checkout',
		ecommerce: {
			checkout: {
				actionField: { step: 1 },
				products: [],
			},
		},
	});
}

function checkoutReservationProgress(treatment) {
	// window.gtag('event', 'checkout_progress', {
	// 	items: [formatTreatment(treatment)],
	// 	coupon: '',
	// });
	window.dataLayer.push({
		event: 'checkout',
		ecommerce: {
			checkout: {
				actionField: { step: 2 },
				products: [formatTreatment(treatment)],
			},
		},
	});
}

export default {
	init() {
		const tags = document.querySelectorAll('[data-tag]');

		tags.forEach((tag) => {
			tag.addEventListener('click', setTags);
		});

		const treatment = ns.productPage;
		if (treatment) {
			const { branch } = ns;
			// window.gtag('event', 'view_item', {
			// 	items: [
			// 		{
			// 			name: treatment,
			// 			brand: branch,
			// 		},
			// 	],
			// });
			window.dataLayer.push({
				event: 'item_view',
				ecommerce: {
					detail: {
						actionField: {},
						products: [{
							name: treatment,
							brand: branch,
						}],
					},
				},
			});
		}
	},

	addToCart(product) {
		addRemoveCart(product, true);
	},
	removeFromCart(product) {
		addRemoveCart(product, false);
	},

	checkout,
	checkoutProgress,
	payment,
	paymentReservation,

	addReservationToCart,
	checkoutReservation,
	checkoutReservationProgress,
};
