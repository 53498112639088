import $ from 'jquery';
import moment from 'moment';
import ns from './Namespace';
import { getParameterByName } from './utils/getParameterByName';
import { 
	getEndpointUrl, 
	GET_STATES, 
	GET_COUNTRIES, 
	GET_AVAILABILITY, 
	GET_AVAILABILITY_HOURS,
} from './Api';
import CustomEvent from './EventPolyfill';

export const BOOKING_MANAGER_UPDATE = 'BOOKING_MANAGER_UPDATE';
export const PACKAGE_PREFIX = 'pckg';

const USA_ID = 1;
const CANADA_ID = 2;
export const STATE_ALLOWED_COUNTRIES = [USA_ID, CANADA_ID];

function keep(obj, params = []) {
	const new_obj = {};
	params.forEach(p => {
		new_obj[p] = obj[p];
	});
	return new_obj;
}

let locations = null; //eslint-disable-line
let treatment_groups = null;
let lastDateUpdatesCall = null;
let lastScheduleUpdatesCall = null;
let hash = '';
let nextUpdate = null;
let isForReservation = null;

const params = {
	location: null,
	treatment_group: null,
	package: null,
	duration: null,
	date: null,
	schedule: null,
	is_duo: false,
	has_gender: true,
	nb_guests: 1,
	is_exp_thermic: true,
	genders: [null, null, null, null],
	extras: {
		robe: false,
		water_bottle: false,
		healthy_lunch: false,
	},
	country: null,
	state: null,
	availabilities: [],
};

class BookingManager {

	static init(isForReservationP) {
		isForReservation = isForReservationP;
		locations = ns.locations.map(loc => keep(loc, ['id', 'booker_id', 'name']));

		treatment_groups = ns.treatment_groups.map((treatment_group) => {
			// console.log(treatment_group);

			const tg = keep(treatment_group, ['id', 'code', 'name_lng', 'order', 'category_id', 'can_book']);
			tg.has_gender = Number(treatment_group.treatment_category && treatment_group.treatment_category.has_gender);
			tg.treatments = ns.treatments.filter(treatment => {
				const groupId = (treatment.generic_group_id !== '0' && treatment.generic_group_id) || treatment.group_id;
				// console.log(groupId);
				return groupId === tg.id;
			});
			tg.locations = tg.treatments.reduce((arr, v) => {
				if (!arr.includes(v.location_id)) arr.push(v.location_id);
				return arr;
			}, []);

			return tg;
		});
		// console.log(treatment_groups);
		treatment_groups.sort((a, b) => a.order - b.order);

		this.handleDefaultParams();
	}

	static debugParams() {
		return params;
	}

	static handleDefaultParams() {
		const treatment = getParameterByName('treatment');
		const num_guests = getParameterByName('numguests');
		// console.log(num_guests);
		if (!treatment || !num_guests) return;
		
		this.setLocation(ns.location_id.toString(), false);
		this.setTreatment(treatment, false);
		this.setNumGuests(parseInt(num_guests, 10));
	}

	static triggerChange() {
		const ev = new CustomEvent(BOOKING_MANAGER_UPDATE);
		ev.params = params;
		window.dispatchEvent(ev);
	}

	static updateStates(country) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: getEndpointUrl(GET_STATES),
				data: { country },
				dataType: 'json',
				method: 'post',
				success(data) {
					console.log(data.States);
					resolve(data.States);
				},
				error: reject,
			});
		});
	}

	static updateDates() {
		const service = this.computeService(false);
		const datesHash = JSON.stringify(params);

		if (!service) return false;

		const data = {
			num: params.nb_guests,
			location: params.location.booker_id,
			service: service.booker_id,
			start: moment().add(1440, 'minutes').format(),
			end: moment().add(3, 'months').format(),
			genders: params.genders,
		};
		// console.log(data);

		const props = {
			method: 'post',
			url: getEndpointUrl(GET_AVAILABILITY),
			data,
		};

		// console.log(props);

		return new Promise((resolve, reject) => {
			lastDateUpdatesCall = datesHash;

			props.success = (result) => {
				// console.log(result);
				if (lastDateUpdatesCall === datesHash) {
					if (result.length === 0) {
						reject(new Error('no-result'));
					} else {
						if (params.nb_guests === 1) {
							resolve(result[0].serviceCategories[0].services[0].availability);
							return;
						}
						resolve(result.availability);
					}
				} else {
					console.log('already received');
				}
			};
			props.error = (e) => {
				reject(e);
			};
			$.getJSON(props);
		});
	}

	static updateSchedule() {
		const service = this.computeService(false);
		if (!service) return false;

		const scheduleHash = JSON.stringify(params);

		const data = {
			num: params.nb_guests,
			location: params.location.booker_id,
			service: service.booker_id,
			start: params.date,
			genders: params.genders,
		};
		// console.log(data);

		const props = {
			method: 'post',
			url: getEndpointUrl(GET_AVAILABILITY_HOURS),
			data,
		};

		return new Promise((resolve, reject) => {
			lastScheduleUpdatesCall = scheduleHash;

			props.success = (raw) => {
				// console.log(raw);
				if (lastScheduleUpdatesCall === scheduleHash) {
					//multiple le retourne en objet, single en array...
					const result = (raw && raw[0]) || raw;
					if (!result) {
						reject(new Error('no-result'));
					} else {
						if (params.nb_guests === 1) {
							const serviceCat = result.serviceCategories.filter(cat => cat.serviceCategoryId === parseInt(service.category_id, 10))[0];

							const bookerService = serviceCat.services.filter(s => s.serviceId === parseInt(service.booker_id, 10))[0];

							const availabilities = bookerService.availability;

							params.availabilities = availabilities;
							resolve(availabilities);
							return;
						}

						params.availabilities = result.availability;
						resolve(result.availability);
					}
				}
			};
			props.error = (e) => {
				reject(e);
			};
			$.getJSON(props);
		});
	}

	static computeService(isForGiftCard) {

		let service;
		const filteredServices = params.treatment_group && params.treatment_group.treatments.filter((t) => {
			return (
				t.location_id === params.location.id
				&& t.duration === params.duration
				&& (t.is_duo === '1') === params.is_duo
			);
		});
		// console.log(filteredServices);
		if (!filteredServices) return null;
		//if only one found, no need to filter further
		if (filteredServices.length === 1) [service] = filteredServices;

		//for reservation, we don't need to specify Thermal experience, so return the servic that does not have it
		if (!service) {
			if (!isForGiftCard) {

				service = filteredServices.find((t) => {
					return t.includes_thermal_experience !== '1';
				});

				//if one exists without thermal experience, it's the default, return it. Otherwise, return the first one (failsafe)
				if (!service) {
					[service] = filteredServices;
				}

			} else {
				//for gift cards, we need to take into account the thermal experience, but we need to return the highest priced.
				const thermalFiltered = filteredServices.filter((t) => {
					return (t.includes_thermal_experience === '1') === params.is_exp_thermic;
				}).sort((a, b) => {
					return Number(a.price) - Number(b.price);
				});
				service = thermalFiltered.pop();

			}
		}

		if (service) {
			service.category_id = params.treatment_group && params.treatment_group.category_id;
		}
		return service;

		// const service = params.treatment_group && params.treatment_group.treatments.find((t) => {
		// 	// console.log(
		// 	// 	'tloc ' + t.location_id,
		// 	// 	'ploc ' + params.location.id,
		// 	// 	'tdur ' + t.duration,
		// 	// 	'pdur ' + params.duration,
		// 	// 	'tisduo ' + (t.is_duo === '1'),
		// 	// 	'pisduo ' + params.is_duo,
		// 	// 	'ttherm ' + (t.includes_thermal_experience === '1'),
		// 	// 	'ptherm ' + params.is_exp_thermic
		// 	// );

		// 	//if we are searching for gift card, we ant the highest priced service that matches (as thermal experience is more expensive in the weekend)

		// 	return (
		// 		t.location_id === params.location.id
		// 		&& t.duration === params.duration
		// 		&& (t.is_duo === '1') === params.is_duo
		// 		&& (t.includes_thermal_experience === '1') === params.is_exp_thermic
		// 	);
		// });
		
	}

	static queueUpdate() {
		if (!nextUpdate) {
			nextUpdate = requestAnimationFrame(() => {
				nextUpdate = false;

				const p = JSON.stringify(params);
				if (hash !== p) {
					hash = p;
					this.triggerChange();
				}
			});
		}
	}


	/* SETTERS */

	static setCountry(country) {
		params.country = country;
	}

	static setState(state) {
		params.state = state;
	}

	static setLocation(location_id, shouldQueue = true) {
		// console.log(location_id);
		if (!params.location || location_id !== params.location.id) {
			params.location = locations.find(x => x.id === location_id);

			if (shouldQueue) {
				this.setTreatment();
			}
		}

		if (shouldQueue) {
			this.queueUpdate();
		}
	}

	static setTreatment(treatment_group_id = null, shouldQueue = true) {

		//is it a package? (for gift cards only, as of 2018-10-19)
		if (treatment_group_id && String(treatment_group_id).indexOf(PACKAGE_PREFIX) === 0) {
			const package_id = treatment_group_id.replace(PACKAGE_PREFIX, '');
			params.package = ns.packages.find(x => x.id === (package_id));
			// console.log(ns.packages);
			// console.log(treatment_group_id, package_id, params.package);
			params.treatment_group = null;

		} else {
			params.package = null;
			// console.log('set', treatment_group_id);
			const previous = params.treatment_group && params.treatment_group.id;

			if (!treatment_group_id) {
				const hasTreatmentInLocation = params.treatment_group && params.treatment_group.treatments.find(t => t.location_id === params.location.id);

				if (!hasTreatmentInLocation) {
					params.treatment_group = this.getDefaultTreatment();
				}
			} else {
				params.treatment_group = treatment_groups.find(x => x.id === (treatment_group_id));
			}

			// console.log(params.treatment_group);
			//some services don't allow to change gender of personnel
			this.setHasGender(params.treatment_group.has_gender);
			
			if (!previous || previous !== params.treatment_group.id) {

				//if this treatment is only duo, set value as duo
				// console.log(this.isOnlyDuo());
				// console.log(params.treatment_group);
				
				if (this.isOnlyDuo()) {
					this.setDuo(true);
				} else {
					params.nb_guests = 1;
					this.setDuo(false);
				}

				this.setDuration(params.duration);
			}
		}

		if (shouldQueue) {
			this.queueUpdate();
		}

	}

	static setDuration(duration = null, shouldQueue = true) {
		if (duration) {
			if (this.getDurations().includes(duration)) {
				params.duration = duration;
			} else {
				params.duration = this.getDefaultDuration();
			}
		} else {
			params.duration = this.getDefaultDuration();
		}

		if (shouldQueue) {
			this.queueUpdate();
		}
	}

	static setNumGuests(num, shouldQueue = true) {
		params.nb_guests = Number(num);

		if (params.is_duo && !this.canDuo()) {
			this.setDuo(false);
		}

		if (shouldQueue) {
			this.queueUpdate();
		}
	}

	static setDuo(is_duo) {
		params.is_duo = is_duo;
		params.nb_guests = is_duo ? 2 : params.nb_guests;
		this.setDuration(params.duration);
		this.queueUpdate();
	}

	static setThermicExp(thermic_exp) {
		params.is_exp_thermic = thermic_exp;
		this.setDuration(params.duration);
		this.queueUpdate();
	}

	static setGenders(genders) {
		params.genders = genders;
		this.queueUpdate();
	}

	static setDate(date) {
		params.date = moment(date).format(); //eslint-disable-line
	}

	static setSchedule(datetime) {
		params.schedule = datetime;
	}


	/* GETTERS */

	static getStates() {
		return params.states;
	}

	static getCountry() {
		return params.country;
	}

	static getHasGender() {
		return params.has_gender;
	}

	static setHasGender(v) {
		params.has_gender = v;
	}

	static getLocation() {
		return params.location;
	}

	static getTreatmentGroups(location_id = null) {
		const loc = location_id || params.location.id;
		return treatment_groups.filter(tg => tg.locations.includes(loc));
	}

	static getTreatments() {
		// console.log(treatment_groups);
		return treatment_groups.filter((group) => {
			// console.log(!isForReservation, Number(group.can_book), group.name_lng);
			return (!isForReservation || Number(group.can_book)) && group.treatments.find((treatment) => {
				return treatment.location_id === params.location.id;
			});
		});
	}

	static getPackages() {
		return ns.packages.filter((pack) => {
			return pack.location_id === params.location.id;
		});
	}

	static getPackage() {
		return params.package;
	}

	static getTreatment() {
		// console.log(params.treatment_group);
		return params.treatment_group;
	}

	static getDuration() {
		return params.duration;
	}

	static getDurations() {
		// console.log(params.treatment_group);
		return ((params.treatment_group && params.treatment_group.treatments) || [])
			.reduce((arr, v) => {
				if (
					!arr.includes(v.duration)
					&& (v.is_duo === '1') === params.is_duo
					// && (v.includes_thermal_experience === '1') === params.is_exp_thermic
					&& v.location_id === params.location.id
				) arr.push(v.duration);
				return arr;
			}, []);
	}

	static getDate() {
		return params.date;
	}

	static getNbGuests() {
		return params.nb_guests;
	}

	static getThermicExperience() {
		return params.is_exp_thermic;
	}

	static getGenders() {
		return params.genders;
	}

	static getPrice(isForGiftCard) {
		const service = this.computeService(isForGiftCard);
		// console.log(service);
		// console.log(params.nb_guests);
		// console.log(params);
		if (service) {
			return (parseFloat(service.price) * (params.nb_guests)).toFixed(2);
		}

		const pack = this.getPackage();
		// console.log(pack);
		if (pack) return (parseFloat(pack.price)).toFixed(2);

		return '0.00';
	}

	static getDefaultTreatment() {
		return this.getTreatments().sort((a, b) => a.order - b.order)[0];
	}

	static getDefaultDuration() {
		return this.getDurations().sort((a, b) => parseFloat(a) - parseFloat(b))[0];
	}

	static getSchedule() {
		return params.schedule;
	}

	static getAvailabilities() {
		return params.availabilities;
	}

	static canDuo() {
		// console.log(params.treatment_group.treatments);
		return Number(params.nb_guests) === 2 && params.treatment_group && params.treatment_group.treatments.find(x => x.is_duo === '1');
	}

	static isOnlyDuo() {
		return params.treatment_group.treatments.reduce((isDuo, x) => {
			return isDuo && x.is_duo === '1';
		}, true);
	}
}

window.BookingManager = BookingManager;

export default BookingManager;
