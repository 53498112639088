
'format es6';

import $ from 'jquery';

let isMobile = false;

function CollapseFilters() {
	const trigger = $('[data-trigger]');
	const target = trigger.siblings('[data-target]');

	if (window.matchMedia('(max-width: 991px)').matches && !isMobile) {
		isMobile = true;
		target.slideUp();
		
		trigger.off('click.skyspa.filters').on('click.skyspa.filters', () => {
			target.slideToggle(300, () => {
				target.toggleClass('open');
				target.attr('style', '');
			});
			trigger.toggleClass('open');
		});
	} else if (window.matchMedia('(min-width: 991px)').matches && isMobile) {
		isMobile = false;
		target.attr('style', '');
		target.removeClass('open');
		trigger.removeClass('open');
	}
}

function changeLocation(e) {
	const curr = $(e.currentTarget);
	const currFilter = curr.data('filter');
	const currId = curr.val();

	window.location.search = `search[${currFilter}]=${currId}`;
}

export default {
	init() {
		$(window).on('resize.skyspa.filters', CollapseFilters);
		CollapseFilters();

		const filters = $('[data-filter]');

		filters.on('change.skyspa.filters', (e) => {
			changeLocation(e);
		});
	},
};
