'format es6';
'use strict';

import gsap, { TweenMax, TimelineMax } from 'gsap';

import { isElementVisible } from './utils/isElementVisible';

let main;

function topElem(arrowContainer, lineHeight) {
	const wh = main.clientHeight;
	const caculatedTop = wh - (arrowContainer.getBoundingClientRect().top + 100);

	return caculatedTop <= lineHeight - 100 ? caculatedTop : lineHeight - 100;
}

function onScroll() {
	const lines = Array.from(main.querySelectorAll('[data-line]'));
	const stickyElem = Array.from(main.querySelectorAll('[data-scroll-container]'));

	if (lines) {
		lines.forEach((line) => {
			const arrowLeft = line.querySelector('.arrow-left');
			const arrowRight = line.querySelector('.arrow-right');
			const arrowContainer = line.querySelector('[data-arrow]');
			const lineHeight = line.clientHeight;
			
			const topPosition = topElem(arrowContainer, lineHeight, main);
			if (topPosition >= 0) {
				TweenMax.to(arrowContainer, 0.2, { top: topPosition });
			}
	
			if (isElementVisible(line) && arrowLeft !== null) {
				const tl = new TimelineMax();
				
				tl.to(arrowLeft, 0.5, { scaleY: 1 })
					.to(arrowRight, 0.5, { scaleY: 1 });
			}
		});
	}

	if (stickyElem) {
		stickyElem.forEach((elem) => {
			if (elem.getBoundingClientRect().bottom <= 500) {
				elem.classList.add('hide');
			} else {
				elem.classList.remove('hide');
			}
		});
	}
}

export default {
	init() {
		main = document.querySelector('[data-main]');
		if (main) {
			document.querySelector('main').addEventListener('scroll', () => {
				onScroll();
			});
		}
		onScroll();
	},
};
