import ns from './Namespace';
import Cart, {
	TYPE_GIFTCARD,
	DELIVERY_EMAIL,
	DELIVERY_MAIL,
} from './Cart';

const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const dom = {};

function setupDomReferences() {
	dom.add_cart = dom.form.querySelector('[data-add-cart]');
	dom.template_row = dom.form.querySelector('[data-template-row]');
	dom.templates = Array.from(dom.form.querySelectorAll('input[name=templates]'));
	dom.card_types = Array.from(dom.form.querySelectorAll('input[name=card_type]'));
	dom.delivery_types = Array.from(dom.form.querySelectorAll('input[name=delivery_type]'));

	dom.recipient = dom.form.querySelector('[data-gift-card-email]');
	//notice of mail delivery details
	dom.shipping_precisions = dom.form.querySelector('[data-shipping-precisions]');
	dom.pickup_precisions = dom.form.querySelector('[data-pickup-precisions]');
	

	dom.qty = dom.form.querySelector('input[name=qty]');
	dom.qty_row = dom.form.querySelector('[data-gift-card-qty]');

	dom.price = dom.form.querySelector('[data-price]');
	dom.card_value = dom.form.querySelector('[data-gift-card-value]');
	dom.to = dom.form.querySelector('input[name=to]');
	dom.to_email = dom.form.querySelector('input[name=to_email]');
	dom.from = dom.form.querySelector('input[name=from]');
	dom.message = dom.form.querySelector('textarea[name=message]');

	dom.choose_card = dom.form.querySelector('[data-choose-card]');
	dom.choose_certificate = dom.form.querySelector('[data-choose-certificate]');

}

function getCardType() {
	return dom.card_types.find(x => x.checked).value;
}

function getDeliveryType() {
	return dom.delivery_types.find(x => x.checked).value;
}

function updatePrice() {
	let price = parseInt(dom.card_value.value, 10).toFixed(2);
	if (price < 0 || Number.isNaN(Number(price))) price = 0;

	const qty = getQty();
	price *= qty;
	if (ns.lang === 'fr') {
		price = `${price}$`;
	} else {
		price = `$${price}`;
	}
	dom.price.innerHTML = price;
}

function getQty() {
	let qty = Math.floor(Number(dom.qty.value));

	//always 1 for delivery by email
	if (getDeliveryType() === DELIVERY_EMAIL) {
		qty = 1;
	}
	dom.qty.value = qty || 1;
	// console.log(qty);
	return qty;
}

function onChangeDeliveryType() {
	const deliveryType = getDeliveryType();

	if (deliveryType === DELIVERY_EMAIL) {

		//NOTE 2018-12-06 y'en a juste 1 template, mais normalement devrait etre block
		dom.template_row.style.display = 'none';
		
		dom.recipient.style.display = 'block';
		dom.shipping_precisions.style.display = 'none';
		dom.pickup_precisions.style.display = 'none';

		//by email: only one at a time (multiple quantities don't exist in Booker for these)
		dom.qty.value = 1;
		dom.qty_row.style.display = 'none';

	} else if (deliveryType === DELIVERY_MAIL) {
		dom.template_row.style.display = 'none';
		dom.recipient.style.display = 'none';
		dom.shipping_precisions.style.display = 'block';
		dom.pickup_precisions.style.display = 'none';
		dom.qty_row.style.display = 'block';

	} else {
		dom.shipping_precisions.style.display = 'none';
		dom.pickup_precisions.style.display = 'block';
		dom.template_row.style.display = 'none';
		dom.recipient.style.display = 'none';
		dom.qty_row.style.display = 'block';
	}
}

function onCheckCanAddToCart() {
	const deliveryType = getDeliveryType();
	const cardType = getCardType();

	let isValid = true;

	//for certificates, we need to have name, email, etc.
	if (deliveryType === DELIVERY_EMAIL) {
		if (dom.from.value === '' || dom.to.value === '' || !emailRegexp.test(dom.to_email.value) || dom.message.value === '') {
			isValid = false;
		}
	}

	const q = getQty();
	if (!q) isValid = false;

	//for amounts, make sure it is not 0
	if (cardType === TYPE_GIFTCARD && parseInt(dom.card_value.value, 10) > 0) {
		isValid = true;
	}

	const fcn = isValid ? 'remove' : 'add';
	dom.add_cart.classList[fcn]('disabled');
}

function onClickAddToCart() {
	if (!dom.add_cart.classList.contains('disabled')) {
		const type = getCardType();
		const deliveryType = getDeliveryType();
		
		const treatment = null;
		const pckg = null;

		const price = parseInt(dom.card_value.value.replace('$', ''), 10);
		const description = `${ns.tx.cartGiftCard} ${price}$`;

		const item = {
			type,
			delivery_type: deliveryType,
			treatment,
			package: pckg,
			description,
			value: price,
			quantity: getQty(),
		};

		if (deliveryType === DELIVERY_EMAIL) {
			item.template_id = dom.templates.find(x => x.checked).value;
			item.to = dom.to.value;
			item.from = dom.from.value;
			item.message = dom.message.value;
			item.to_email = dom.to_email.value;
		}

		Cart.addItem(item);
	}
}

function init() {
	dom.form = document.querySelector('[data-gift-card-form]');
	if (!dom.form) return;

	setupDomReferences();

	dom.form.addEventListener('input', onCheckCanAddToCart);

	dom.card_value.addEventListener('input', updatePrice);
	dom.qty.addEventListener('input', getQty);
	dom.qty.addEventListener('input', updatePrice);
	dom.delivery_types.forEach(x => x.addEventListener('input', onChangeDeliveryType));

	dom.add_cart.addEventListener('click', onClickAddToCart);
	onChangeDeliveryType();

	updatePrice();
}

export default {
	init,
};
