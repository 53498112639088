import { TweenMax } from 'gsap';
import { stringify } from 'querystring';

function tweenFog(el) {
	if (el) {
		TweenMax.to(el, 24, {
			scaleX: 1.3,
			scaleY: 1.4,
			repeat: -1,
			yoyo: true,
		});
	}
}
	
function init() {
	const elem = document.querySelectorAll('[data-fog]');
	if (elem) {
		elem.forEach((el) => {
			const bg = el.querySelector('[data-fog-bg]');
			tweenFog(bg);
		});
	}
}
export default {
	init,
};
