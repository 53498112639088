'format es6';
'use strict';

export const GET_STATES = 'getStates';
export const GET_COUNTRIES = 'getCountries';
export const GET_AVAILABILITY = 'getAvailability';
export const GET_AVAILABILITY_HOURS = 'getAvailabilityHours';
export const CREATE_APPOINTMENT = 'createAppointment';
export const GET_TOTAL = 'getTotal';
export const PAYMENT = 'payment';

export function getEndpointUrl(endpoint) {
	return `/c/forena/forena/endpoints/${endpoint}.php`;
}
